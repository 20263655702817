import TransitionPage from "@/components/UI/TransitionPage/index";
import { mapGetters } from "vuex";

export default {
  components: {
    TransitionPage,
  },
  data() {
    return {
      activename: "1-1",
      opennames: ["1"],
      routeArr: {
        "/uc/safe": "1-1",
        "/uc/account": "1-2",
        "/uc/money": "2-1",
        "/uc/record": "2-2",
        "/uc/recharge": "2-3",
        "/uc/withdraw": "2-4",
        "/uc/trade": "2-5",
        "/uc/paydividends": "2-6",
        "/uc/blc": "2-7",
        "/uc/bjc": "2-8",
        "/uc/entrust/current": "3-1",
        "/uc/entrust/history": "3-2",
        "/uc/ad": "4-1",
        "/uc/order": "4-2",
        "/uc/withdraw/address": "2-4",
        "/uc/ad/create": "4-3",
        "/uc/ad/update": "4-4",
        "/uc/promotion/mypromotion": "5-1",
        "/uc/promotion/mycards": "5-2",
        "/uc/innovation/myorders": "6-1",
        "/uc/innovation/myminings": "6-2",
      },
      ucNavDrawerModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getWithdrawStatus: "global/GET_WITHDRAW_STATUS",
      getDepositStatus: "global/GET_DEPOSIT_STATUS",
    }),
    withdrawStatus() {
      return this.getWithdrawStatus;
    },
    depositStatus() {
      return this.getDepositStatus;
    },
    currentRoute() {
      return this.$route.path;
    },
    renderChild() {
      if (this.withdrawStatus && this.currentRoute.includes("withdraw")) {
        return true;
      } else if (this.depositStatus && this.currentRoute.includes("recharge")) {
        return true;
      } else if (
        !this.currentRoute.includes("withdraw") &&
        !this.currentRoute.includes("recharge")
      ) {
        return true;
      }
    },
  },
  created() {
    // this.init();
    const path = this.$route.path;
    this.heightLightMenu(path);
  },
  methods: {
    // init() {
    //   this.$store.commit("navigate", "nav-assets");
    //   this.$store.state.HeaderActiveName = "0";
    //   this.$store.state.HeaderActiveName = "1-6";
    //   if (!localStorage.TOKEN || !localStorage.MEMBER) {
    //     this.$Message.success(this.$t("common.logintip"));
    //     this.$router.push("/login");
    //   }
    // },
    toggleMemu() {
      this.ucNavDrawerModal = !this.ucNavDrawerModal;
    },
    sss(name) {
      let index = 1;
      if (name.length >= 1) {
        index = name[name.length - 1];
        this.opennames = [index];
        this.activename = index + "-1";
        this.link(this.activename);
      } else {
        return;
      }
    },
    link(code) {
      switch (code) {
        case "1-1":
          this.$router.push("/profile");
          break;
        case "2-1":
          this.$router.push("/assets");
          break;
        case "3-1":
          this.$router.push("/open-orders");
          break;
        case "4-1":
          this.$router.push("/ad");
          break;
        case "5-1":
          this.$router.push("/promotion/mypromotion");
          break;
        case "5-2":
          this.$router.push("/promotion/mycards");
          break;
        case "6-1":
          this.$router.push("/innovation/myorders");
          break;
        case "6-2":
          this.$router.push("/innovation/myminings");
          break;
        default:
          this.$router.push("/profile");
          break;
      }
    },
    heightLightMenu(path) {
      let acName = this.routeArr[path] || "1-1",
        opName = acName.split("-")[0];
      this.opennames = [opName];
      this.activename = acName;
      this.$nextTick(function () {
        // this.$refs.test.updateOpened();
        // this.$refs.test.updateActiveName();
      });
    },
  },
  watch: {
    $route(to, form) {
      this.heightLightMenu(to.path);
    },
  },
  mounted() {
    this.$nextTick(function () {
      // this.$refs.test.updateOpened();
      // this.$refs.test.updateActiveName();
    });
  },
};
